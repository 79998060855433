module.exports={
"label.account.name":`名字`,
"label.account.copy":`请复制邮件中的随机验证码，点击链接进行下一步修改密码`,
"label.account.phone.length":`手机号只能为7位以上`,
"label.account.Start.trial":`开始试用`,
"label.account.employees1000":`超过1000 名员工`,
"label.account.other.Ways":`其他登陆方式`,
"label.account.emailsync.button.cancel":`取消`,
"label.account.rule":`由8-16位数字和字母组成`,
"label.account.password.update.success":`密码修改成功`,
"label.account.describe":`CloudCC主打数字个性化、分析智能化、 连接全球化的功能特点，伴随企业业务发展而随需定制。`,
"label.account.enter.Emailbox":`请输入邮箱`,
"label.account.password":`密码`,
"label.account.problem":`登录有问题`,
"label.account.Complete.reset":`邮箱，请输入验证码完成重置密码`,
"label.account.crm.used":`上市公司都在用的CRM`,
"label.account.report.loading":`拼命加载中···`,
"label.account.notcustomer":`不是客户？`,
"label.account.employees501":`501-1000 名员工`,
"label.account.title.operationManager":`运营经理`,
"label.account.Preset":`预置的项目管理工具`,
"label.account.company":`公司`,
"label.account.come":`欢迎使用cloudcc客户成功平台。我们的全新lightning平台对您提供了最快速、最完整的解决方案，让您能够以客户为中心开展一切工作。`,
"label.account.input.password":`请输入密码`,
"label.account.emailobject.emaildetail.button.back":`返回`,
"label.account.title.itManager":`IT经理`,
"label.account.Modified.successfully":`密码修改成功,请点击重新登录返回登录页`,
"label.account.only11":`请输入至少7位数字`,
"label.account.signin":`登录`,
"label.account.register":`注册获取`,
"label.account.adopt":`验证通过！`,
"label.account.title.hrManager":`市场/人事经理`,
"label.account.input.usernames":`请输入用户名`,
"label.account.only.Numbers":`手机号只能输入数字`,
"label.account.title.salesManager":`销售经理`,
"label.account.email":`电子邮件`,
"label.account.click":`点击`,
"label.account.and":`和`,
"label.account.mobile":`手机`,
"label.account.Privacy.policy":`隐私政策`,
"label.account.password.isnull":`密码不能为空`,
"label.account.employees101":`101-500 名员工`,
"label.account.remember.name":`记住用户名`,
"label.account.Select.country":`请选择国家/地区`,
"label.account.distinguish":`密码区分大小写。`,
"label.account.fillMobile":`请输入电话号`,
"label.account.Preconfigured":`预配置报表和仪表板`,
"label.account.Select.job":`请选择职务`,
"label.account.employees21":`21-100 名员工`,
"label.account.username":`用户名`,
"label.account.Submit":`提交`,
"label.account.confirm":`账户确认`,
"label.account.contact":`联系我们`,
"label.account.country":`国家/地区（国家或地区）`,
"label.account.username.isnull":`用户名不能为空`,
"label.account.forget.password":`忘记密码`,
"label.account.Loading":`装载中`,
"label.account.goon":`继续`,
"label.account.Register.trial":`注册即可免费试用`,
"label.account.title.other":`其他`,
"label.account.surname":`姓氏`,
"label.account.Fail":`验证失败，请重试`,
"label.account.Terms.service":`服务协议`,
"label.account.Select.staff":`请选择员工数量`,
"label.account.username.enter":`请输入姓名`,
"label.account.trial":`免费试用`,
"label.account.employees1":`少于20名员工`,
"label.account.Quick.registration":`快速注册`,
"label.account.tabpage.ok":`确定`,
"label.account.normal.reregister":`重新登录`,
"label.account.batchadd.newbatchpage.save.em":`邮箱格式不正确`,
"label.account..user.title":`职务`,
"label.account.No.credit.card.required":`无需信用卡`,
"label.account.enterprise.Wechat":`企业微信`,
"label.account.sample.data":`预加载示例数据`,
"label.account.title.hobbies":`个人爱好`,
"label.account.platform":`全新智能化、移动化、数字化CRM平台`,
"label.account.sent":`已发邮件`,
"label.account.Confirm.password":`再次输入新密码`,
"label.account.fill":`请填写所有`,
"label.account.user.resetpw":`重置密码`,
"label.account.Spell":`拖动滑块完成拼图`,
"label.account.identifying.code":`请输入验证码`,
"label.account.staff":`员工`,
"label.account.welcome":`欢迎使用CloudCC`,
"label.account.online.guidance":`线上提供咨询与操作指导`,
"label.account.login.CloudCC":`登录到 CloudCC`,
"label.account.Company.name":`请输入公司名称`,
"label.account.title.generalManager":`总经理`,
"label.account.enter.user":`要重置密码，请输入CloudCC用户名。`,
"label.account.sent.code":`已发送验证码到`,
"label.account.pw.error.text":`两次输入的密码不一致`,
"label.account.title.customerManager":`客服经理`,
"label.account.label.captcha":`验证码`,
"label.account.Trial.days":`注册获得30天免费试用`,
"label.account.please.enter.name":`请输入名字`,
"label.account.inspect":`如果您没有收到验证码确认邮件，请检查垃圾邮件`,
"label.account.User.rule":`用户名的形式是电子邮件地址。`,
"label.account.go.Login":`有账户？`,
"label.account.Reading.consent":`我同意`,
"label.account.Change.Password2":`更改密码`,
"label.account.Loginby":`登录-`,
"label.account.apply.partner":`申请成为CloudCC合作伙伴`,
"label.account.MobileLogin":`手机号密码登录`,
"label.account.Registration":`注册中···`,
"label.account.Italy":`意大利`,
"label.account.view.details":`查看详情`,
"label.account.accept":`请阅读并接受服务条款和隐私政策。`,
"label.account.China.Mainland":`中国大陆`,
"label.account.NewPassword":`请输入新密码`,
"label.account.Mobile.not.empty":`手机号不能为空`,
"label.account.Please.select":`请选择`,
"label.account.market.introduce":`统一的市场营销管理平台，助您获客`,
"label.account.IOS":`扫码下载iOS客户端`,
"label.account.failsend":`发送失败`,
"label.account.UpperLowerlAphanumeric.character":`必须包含大小写字母数字及符号`,
"label.account.New.Password1":`新密码`,
"label.account.succSend":`已发送到您的邮箱，请复制邮件内验证码，进行重置密码`,
"label.account.Account.mobile":`账号或手机号`,
"label.account.Login.consent":`登录即视为同意`,
"label.account.fillAreaCode":`请填写区号`,
"label.account.active.login":`已安装CloudCC移动版，请激活后直接登陆`,
"label.account.jp":`日本`,
"label.account.partner.success1":`您已成功注册为CloudCC合作伙伴。`,
"label.account.Reset":`重置`,
"label.account.no.user.info":`无法获取用户的信息`,
"label.account.EnterOldPass":`请输入旧密码`,
"label.account.Germany":`德国`,
"label.account.title.content":`立即注册登入CloudCC软件，免费试用30天！`,
"label.account.Taiwan":`中国台湾`,
"label.account.China.Station":`中国站`,
"label.account.sale.introduce":`销售自动化，助您快速成单`,
"label.account.Back":`返回上一步`,
"label.account.market":`市场云`,
"label.account.section.company":`公司信息`,
"label.account.check.email":`我们的客户经理将在一段时间与您联系。请及时查看邮箱`,
"label.account.characters":`5个字符`,
"label.account.International":`国际站`,
"label.account.britain":`英国`,
"label.account.reg.fail.msg":`注册失败，请稍后重试。`,
"label.account.success.reg":`您已成功注册`,
"label.account.company.url":`官网地址`,
"label.account.CannotBeEmpty":`不能为空`,
"label.account.phone.note":`使用CloudCC CRM移动版，随时随地掌控公司业务`,
"label.account.second":`{0}秒后`,
"label.account.Change.Password":`更改您的密码`,
"label.account.Netherlands":`荷兰`,
"label.account.pleaseAgree":`请同意并接受服务条款和隐私策略`,
"label.account.copyright":`北京神州云动科技股份有限公司 版权所有。`,
"label.account.letter":`1个字母`,
"label.account.remember":`想起来了`,
"label.account.accountNum":`账号`,
"label.account.code.not.empty":`验证码不能为空`,
"label.account.Spain":`西班牙`,
"label.account.Alphanumeric":`必须混合使用字母和数字`,
"label.account.obtain":`获取`,
"label.account.company.txt":`如果你没有注册的商业实体，请添加你的名字`,
"label.account.con.browser":`在浏览器中继续浏览`,
"label.account.Android":`扫码下载安卓客户端`,
"label.account.title":`CloudCC 30 天的免费试用 - CloudCC.com`,
"label.account.partner.success2":`我们将在一段时间内与您联系。`,
"label.account.Macao":`中国澳门`,
"label.account.Brazil":`巴西`,
"label.account.service.introduce":`无缝连接服务流程，提高服务响应效率`,
"label.account.codeFailure":`验证码失效`,
"label.account.AccountLogin":`账号密码登录`,
"label.account.pswComplexReqRules":`密码最小长度为{0} 个字符，且{1}`,
"label.account.UpperLowerlAphanumeric":`必须包含大小写字母及数字`,
"label.account.active.username":`请去邮件 {email} 里激活您的账号，并初始化密码`,
"label.account.rememberPassword":`记住密码`,
"label.account.HongKong":`中国香港`,
"label.account.oldPass":`旧密码`,
"label.account.Data.storage":`您的数据将存储在{0}数据中心。`,
"label.account.number":`1个数字`,
"label.account.Poland":`波兰`,
"label.account.service":`服务云`,
"label.account.Incorrect.format":`手机格式有误，请重新录入`,
"label.account.remember.status":`记住登录状态`,
"label.account.sale":`销售云`,
"label.account.No.account.binding":`无账号绑定，请联系系统管理员`,
"label.account.Russia":`俄罗斯`,
"label.account.France":`法国`,
"label.account.partner.content":`CloudCC是一家全球性公司，现在火热招募合作伙伴，持续与伙伴探索各行业的解决方案，快来加入我们的联盟计划吧！`,
"label.account.reg.phone.already":`这个手机号已经存在这个组织。请登录或使用另一个手机号注册。`,
"label.account.Confirm":`确认新密码`,
"label.account.VerificationLogin":`动态验证码登录`,
"label.account.USA":`美国`,
"label.account.mobile.num":`请输入手机号`,
"label.account.MinLength":`密码最小长度为`,
"label.account.change.your.password":`基于安全原因，您现在必须更改密码`,
"label.account.new.password":`输入{0}的新密码。请确保至少包括。`,
"label.account.website.enter":`请输入官网地址`,
"label.account.pswComplexIsZero":`密码最小长度为{0}个字符`
}